@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family: "Poppins", sans-serif;
}

header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 16px 85px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header  .logo{
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}
header  .logo img{
    width: 8rem;
    margin-left: -2rem;
}
.Hero{
    position: relative;
    right: 0;
    width: 100%;
    min-height: 100vh;
    padding: 100px;
    z-index: 2;
}

.Hero video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.text{
    position: absolute;
    z-index: 10;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
}

.text h3{
    font-size: 2em;
    font-weight: 700;
    color: #fff;
    line-height: 1em;
    text-transform: uppercase;
    text-align: center;
}
  
  /* Navigation */
  .main-nav ul {
    display: grid;
    justify-content: center;
    grid-gap: 20px;
    padding: 0;
    list-style: none;
    grid-template-columns: repeat(6, 2fr);
    margin-top:  15rem;
  }


.links{
    background: #fff;
    display: block;
    text-decoration: none;
    padding: 0.8rem;
    text-align: center;
    color: #D94A3D !important;
    text-transform: uppercase;
    font-size: 1.1rem;
    border-radius: 20px;
    outline: none; 
    border: none;
    text-decoration: none;
 }
.links:hover{
    background-color: #000000;
    color: #fff !important;
}
.color-one:hover{
    background-color: #f50707;
    color: #fff !important;
}
.color-two:hover{
    background-color: #8ebaec;
    color: #fff !important;
}
.color-three:hover{
    background-color: #0dd97a;
    color: #fff !important;
}
.color-four:hover{
    background-color: #1426c4;
    color: #fff !important;
}
.color-five:hover{
    background-color: #fce668;
    color: #fff !important;
}
@media (max-width: 767px){
    .main-nav ul {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
}
@media (max-width: 420px){
     .text{
        bottom: 80px;
     }
}